import classNames from "classnames";
import React from "react";
import { load_webfont } from "../../../helpers/webfont_helpers";

export default class FontClass extends React.Component {
  fontClass() {
    let { asset } = this.props;
    let name = asset.attributes ? asset.attributes.name : asset.name;

    load_webfont(name);
    return { fontFamily: `'${name}', sans-serif` };
  }

  render() {
    // GET RID OF THIS WHEN YOU MAKE ORGANIZATION AND SHOW THE SAME STYLE
    if (this.props.showPage) {
      return (
        <div className={classNames('font-div', { [this.props.className]: this.props.className })} style={this.fontClass()} >
          <div> Aa Zz </div>
        </div>
      );
    }
    return (
      <div
        className={
          classNames("m-asset-thumb m-show-full-size-display", {
            [this.props.className]: this.props.className
          })}>
        <span className="m-asset-font-sample" data-testid="asset-preview--font" style={this.fontClass()}>
          {this.props.children ? this.props.children : "AA ZZ"}
        </span>
      </div>
    );
  }
}
